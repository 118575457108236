import { SponsorId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function sponsor__server__deleteSponsor(p: { sponsorId: SponsorId }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const sponsor = await h.Sponsor.getDoc(p.sponsorId);

  if (!sponsor) {
    throw new Error("Cannot delete a sponsor that does not exist");
  }

  await h.Sponsor.update({ id: p.sponsorId, doc: { deletedAtMS: Date.now() } });
  // SERVER_ONLY_TOGGLE
}
sponsor__server__deleteSponsor.auth = async (req: any) => {};

// i18n certified - complete
