import { Org, Sponsor, SponsorId, SponsorOrg, SponsorTeam, TeamId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import { encryptString, generateRandomHexString } from "../../utils/crypto-helpers";

export async function sponsor__server__createBareSponsor(p: {
  sponsor:
    | Omit<SponsorTeam, "id" | "createdAtMS" | "encryptedEditToken">
    | Omit<SponsorOrg, "id" | "createdAtMS" | "encryptedEditToken">;
}): Promise<SponsorId | null> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const sponsorId = h.Sponsor.generateId();

  const editToken = generateRandomHexString().substr(16);
  const encryptedEditToken = encryptString(editToken);

  await h.Sponsor.add({ doc: { ...p.sponsor, ...{ id: sponsorId, createdAtMS: Date.now(), encryptedEditToken } } });

  return sponsorId;
  // SERVER_ONLY_TOGGLE
}

sponsor__server__createBareSponsor.auth = async (r: any) => {
  await validateToken(r);
};

// i18n certified - complete
