import { Sponsor, SponsorId } from "@ollie-sports/models";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftDocSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function sponsor__client__getSingleSponsorSubscription(p: { sponsorId: SponsorId }): BifrostSubscription<Sponsor | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return firestoreLiftDocSubToBifrostSub(h.Sponsor.docSubscription(p.sponsorId));
}

// i18n certified - complete
