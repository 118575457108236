import { getUniversalHelpers } from "../../helpers";

const IMAGE_DIRECTORY = "sponsor_uploads/";

export async function sponsor__client__uploadLogo(p: { name: string; file: any }) {
  const { app } = getUniversalHelpers();
  return app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + Date.now() + p.name)
    .put(p.file);
}

// i18n certified - complete
