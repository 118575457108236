import { SponsorId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import { decryptString } from "../../utils/crypto-helpers";

export async function sponsor__server__getSponsorUrl(p: { sponsorId: SponsorId }) {
  // SERVER_ONLY_TOGGLE
  const sponsor = await getServerHelpers().appOllieFirestoreV2.Sponsor.getDoc(p.sponsorId);
  if (!sponsor) {
    throw new Error("Unable to find sponsor: " + p.sponsorId);
  }
  const editToken = decryptString(sponsor.encryptedEditToken);
  const editUrl = `${getServerHelpers().serverConfig.httpWebappRoot}/sponsor/edit/${p.sponsorId}/${editToken}`;

  return editUrl;
  // SERVER_ONLY_TOGGLE
}

sponsor__server__getSponsorUrl.auth = async (r: any) => {
  await validateToken(r);
};

// i18n certified - complete
