import { Sponsor } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { decryptString } from "../../utils/crypto-helpers";

export async function sponsor__server__updateSponsor(p: { sponsor: Sponsor; editToken: string }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const sponsor = await h.Sponsor.getDoc(p.sponsor.id);

  if (!sponsor) {
    throw new Error("Cannot update a sponsor that does not exist");
  }

  const editToken = decryptString(sponsor.encryptedEditToken);

  if (editToken !== p.editToken) {
    throw new Error("Invalid token. Cannot edit.");
  }

  await h.Sponsor.update({ id: p.sponsor.id, doc: p.sponsor });
  // SERVER_ONLY_TOGGLE
}
sponsor__server__updateSponsor.auth = async (req: any) => {};

// i18n certified - complete
