import { Sponsor } from "@ollie-sports/models";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";
import { SimpleQuery } from "@ollie-sports/firebase-lift";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import _ from "lodash";

export function sponsor__client__getSponsors(p: {
  teamIds: string[];
  orgIds: string[];
  states: string[];
}): BifrostSubscription<Sponsor[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const subscriptionDefinitions: SimpleQuery<Sponsor>[] = [];
  p.teamIds.map(teamId => {
    subscriptionDefinitions.push({ where: [{ type: ["==", "team"] }, { teamId: ["==", teamId] }] });
  });
  p.orgIds.map(orgId => {
    subscriptionDefinitions.push({ where: [{ type: ["==", "org"] }, { orgId: ["==", orgId] }] });
  });
  p.states.map(state => {
    subscriptionDefinitions.push({ where: [{ type: ["==", "regional"] }, { state: ["==", state] }] });
  });

  subscriptionDefinitions.push({ where: [{ type: ["==", "ollie"] }] });

  return firestoreLiftQuerySubToBifrostSub<Sponsor>(
    h.Sponsor.multiQuerySubscription({
      queries: subscriptionDefinitions
    })
  );
}

// i18n certified - complete
